import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
    '&.MuiFormControl-root-MuiTextField-root': {
        transform: 'translate(14px, 13px) scale(1)',
    },
    '&.MuiOutlinedInput-input': {
        padding: '13px 16px',
    },

    '.MuiFormHelperText-root': {
        color: 'rgba(215, 68, 94, 1)',
        lineHeight: '16px',
        fontFamily: "'Inter', sans-serif",
    },

    '& label': {
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(14px, 13px) scale(1)',
        fontFamily: "'Inter', sans-serif",
    },
    '& label.MuiFormLabel-filled': {
        transform: 'translate(14px,-9px) scale(0.75)',
    },
    '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.87)',
        transform: 'translate(14px,-9px) scale(0.75)',
    },
    '& label.Mui-error': {
        color: 'rgba(215, 68, 94, 1)',

    },

    '& .MuiOutlinedInput-root': {
        'input': {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
            lineHeight: '22px',
            padding: '13px 16px',
            fontFamily: "'Inter', sans-serif",
        },
        '&.Mui-error': {
            borderColor: 'rgba(215, 68, 94, 1)',
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(215, 68, 94, 1)',

            },
            'input': {
              caretColor: 'rgba(215, 68, 94, 1)',
            },

        },
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '8px',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 1)',

        },
    },
});