import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import * as globalStyles from '../../../styles/global.module.scss'
import checkboxIcon from './icons/checkbox.svg'
import closeIcon from './icons/close.svg'
import * as yup from 'yup'

import * as styles from './styles.module.scss'
import { useFormik } from 'formik'
import { StyledTextField } from '../TextField'
import { createContext, PropsWithChildren, useState } from 'react'

export const registerDialogContext = createContext({
    handleOpenRegisterDialog: () => {},
})

const emailPattern =
    /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i

const validationSchema = yup.object({
    name: yup.string().defined().min(3, 'Name should be of minimum 3 characters length').required('Name is required'),
    email: yup.string().defined().matches(emailPattern, { message: 'Enter a valid email' }).required('Email is required'),
    agreement: yup.bool().oneOf([true]).required(),
})

const encode = (data: any) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

type RegisterDialogProps = { isOpened: boolean; onClose: () => void }

export const RegisterDialog = ({ isOpened, onClose }: RegisterDialogProps) => {
    const [registerSucceed, setRegisterSucceed] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            agreement: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({ 'form-name': 'register', ...values }),
            })
                .then(() => {
                    resetForm()
                    setRegisterSucceed(true)
                })
                .catch(console.error)
        },
    })

    const handleCloseRegister = () => {
        formik.resetForm()
        onClose()
    }

    const registerFormContent = (
        <>
            <div className="mb-[12px] flex cursor-pointer justify-end">
                <img src={closeIcon} alt="" width={24} height={24} onClick={handleCloseRegister} />
            </div>
            <h4 className="mb-[30px]">Open a new account</h4>
            <form name="register" method="post" data-netlify="true" netlify-honeypot="bot-field" hidden>
                <input type="text" name="name" />
                <input type="email" name="email" />
            </form>
            <form
                className="flex flex-col gap-[32px]"
                onSubmit={formik.handleSubmit}
            >
                <input type="hidden" name="form-name" value="register" />
                <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Your name*"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <StyledTextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email*"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

                <label className="flex gap-[8px] relative cursor-pointer">
                    <input
                        id="agreement"
                        name="agreement"
                        type="checkbox"
                        value={String(formik.values.agreement)}
                        onChange={formik.handleChange}
                        className={classNames(styles.imgWrapper, 'mt-[3px]')}
                    />
                    <img className={classNames('absolute top-[3px]', !formik.values.agreement && 'hidden')} src={checkboxIcon} width={18} height={18} />
                    <span className="text-start">By clicking this button, I consent to being contacted by the company to open an account</span>
                </label>

                <button type='submit' className={classNames(globalStyles.btn)} disabled={formik.isSubmitting || !formik.isValid}>
                    CONFIRM
                </button>
            </form>
        </>
    )

    const registerFormSuccess = (
        <>
            <h4 className="mb-[30px]">Thank you for signing up</h4>
            <p className="mb-[32px]">One of our representatives will get back to you shortly.</p>
            <button onClick={handleCloseRegister} className={classNames(globalStyles.btn)} disabled={!formik.isValid}>
                OK
            </button>
        </>
    )

    return (
        <Dialog open={isOpened} onClose={handleCloseRegister} as="div" className="fixed top-0 z-[3] h-full w-full overflow-auto bg-cts-blue-56">
            <Dialog.Panel className="flex h-full items-center justify-center p-[20px]">
                <div
                    className="w-full max-w-[540px] rounded-[10px] bg-cts-white p-[20px] text-center"
                    style={{
                        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.3)',
                    }}
                >
                    {registerSucceed ? registerFormSuccess : registerFormContent}
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}

export const WithRegisterDialog = ({ children }: PropsWithChildren) => {
    const [isOpened, setIsOpened] = useState(false)

    const handleCloseRegisterDialog = () => {
        setIsOpened(false)
    }

    const handleOpenRegisterDialog = () => {
        setIsOpened(true)
    }
    return (
        <registerDialogContext.Provider value={{ handleOpenRegisterDialog }}>
            <div>
                {children}
                <RegisterDialog isOpened={isOpened} onClose={handleCloseRegisterDialog} />
            </div>
        </registerDialogContext.Provider>
    )
}
